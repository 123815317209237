// membersData.js
// import sample from "../../assets/Team images/sampleimg.png"
import recoil from '../../assets/Core/recoil.png'
import qurious from '../../assets/Core/qurious.png'
import encode from '../../assets/Core/encode.png'
import monochrome from '../../assets/Core/monochrome.png'
import pixel from '../../assets/Core/pixel-perfect.png'
import ciniphilia from '../../assets/Core/cinephilia.png'

import { faHtml5, faCss3Alt, faJs,faReact,faPython,faAws,faNodeJs, faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'; 
import { faInstagram,faFacebook,faDiscord,faTwitter,faReddit,faAndroid } from '@fortawesome/free-brands-svg-icons'; 
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const skillsData = [
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "JavaScript", icon: faJs },
    { name: "React", icon: faReact },
    { name: "Python", icon: faPython },
    { name: "Aws", icon: faAws },
    { name: "NodeJs", icon: faNodeJs },
    { name:"Android", icon:faAndroid },
    
    
];
export const socialMediaData = {
    "facebook": faFacebook,
    "twitter": faTwitter,
    "instagram": faInstagram,
    "discord":faDiscord,
    "reddit": faReddit,
    "linkedin":faLinkedin,
    "github":faGithub,
    "email":faEnvelope

};
export const eventData = [
    {
        name:"Recoil",
        imageurl: recoil
    },
    {
        name:"Encode",
        imageurl: encode
    },
    {
        name:"Qurious",
        imageurl: qurious
    },
    {
        name:"Monochrome",
        imageurl: monochrome
    },
    {
        name:"Pixel Perfect",
        imageurl: pixel
    },
    {
        name:"Ciniphilia",
        imageurl: ciniphilia
    },
    {
        name:"Event Coordinators",
        imageurl: ciniphilia
    },
]



const membersData = [
    {
        name: "Giriman Kohli",
        role: "Club President",
        description: `A versatile individual with a passion for coding, gaming, and entrepreneurship. Whether I'm crafting lines of code, exploring virtual worlds, or chasing innovative ideas, I thrive on creative challenges and strategic thinking. Tech, health, and fitness are integral to my lifestyle.`,
        socialMedia: {
            instagram: "https://www.instagram.com/giriman.png/",
            linkedin:"https://www.linkedin.com/in/giriman-kohli-5b8aba283/",
            twitter:"https://x.com/GirimanKohli",
        },
        skills: [],
        
        
    },
    
    {
        name: "Aayush Ghadale",
        role: "Club Director",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            instagram: "https://www.instagram.com/aaaxyush/",
            linkedin:"https://www.linkedin.com/in/aayush-ghadale-6b261122a/",

        },
        skills: [],
    },
    {
        name: "Viplav Meshram",
        role: "Event Head",
        event_name:"Recoil",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Laabhit Muddalwar",
        role: "Event Deputy Head",
        event_name:"Recoil",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Hardik Mehta",
        role: "Event Deputy Head",
        event_name:"Recoil",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Prem Naik",
        role: "Event Head",
        event_name:"Encode",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Piyush Jha",
        role: "Event Deputy Head",
        event_name:"Encode",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Vishudh Singh Unhale",
        role: "Event Deputy Head",
        event_name:"Encode",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Diya Jaiswani",
        role: "Event Head",
        event_name:"Qurious",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Arham Saklecha ",
        role: "Event Deputy Head",
        event_name:"Qurious",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {

        },
        skills: [],
    },
    {
        name: "Aarav Jain",
        role: "Event Deputy Head",
        event_name:"Qurious",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
        },
        skills: [],
    },
    {
        name: "Khyati Singh",
        role: "Secretary",
        event_name:"Event Coordinators",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            
        },
        skills: [],
    },
    {
        name: "Kritnu Jatav",
        role: "Secretary",
        event_name:"Event Coordinators",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: [],
    },
    {
        name: "Narayani Malviya",
        role: "Event Head",
        event_name:"Monochrome",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: [],
    },
    {
        name: "Vihaan Mahajan",
        role: "Event Deputy Head",
        event_name:"Monochrome",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: [],
    },
    {
        name: "Nikunj Ratre",
        role: "Event Deputy Head",
        event_name:"Monochrome",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: [],
    },
    {
        name: "Aayush Gadhale",
        role: "Event Head",
        event_name:"Ciniphilia",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    {
        name: "Harshit Solanki",
        role: "Event Deputy Head",
        event_name:"Ciniphilia",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
           

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    {
        name: "Amay Gangwati",
        role: "Event Deputy Head",
        event_name:"Ciniphilia",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    {
        name: "Giriman Kohli",
        role: "Event Head",
        event_name:"Pixel Perfect",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            
        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    {
        name: "Umar Qazi",
        role: "Event Deputy Head",
        event_name:"Pixel Perfect",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        socialMedia: {
            

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    
    

];

export default membersData;
