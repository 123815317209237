import {React} from 'react';
import './Home.css';
import miragelogo from '../../assets/Core/thinkathon-logo.png';
import { useNavigate } from 'react-router-dom';
import { faInstagram,faYoutube,faTwitter} from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Timer } from '../../components/timer/timer';
import About from '../About/About'





const Home = () => {
  const navigate = useNavigate();

  
  

  

  
  

  return (
    
    <div className='home-main'>
      <div className="home-core">
          
        <div className='home-hero'>
          <div className='home-hero-logo'>
            
            {/* <img className="home-ellipse-img" alt="" src={ellipse} /> */}
            <img className="home-halolegion-logo" alt="" src={miragelogo} />

            <div className="home-hero-text">
              <h1 className="home-hero-halocon-text">
                <div className="home-hero-halocon-text-span" id='div1'><span>T</span><span>H</span><span>I</span><span>N</span><span>K</span></div>  <div className="home-hero-halocon-text-span" id='div2'><span>A</span><span>T</span><span>H</span><span>O</span><span>N</span></div>
              </h1>
            </div>
          </div>
          <h1 className="home-hero-halocon-text" id='home-hero-text2'>
          <span>T</span><span>H</span><span>I</span><span>N</span><span>K</span><span>A</span><span>T</span><span>H</span><span>O</span><span>N</span>
          </h1>
          
        </div>
        {/* <p className="home-info-text">Registrations Starts in !</p> */}
        <Timer className='home-countdown'/>
        
      </div>
      <About/>
    </div>
  );
}

export default Home;
