
import logo from '../../assets/Core/monochrome.png'
import a1 from '../../assets/Lottie/Animation - 1721404583238.json'
import a2 from '../../assets/Lottie/Animation - 1721404730950.json'
import a3 from '../../assets/Lottie/Animation - 1721404924353 (1).json'
import a4 from '../../assets/Lottie/Animation - 1721404924353.json'
import why from '../../assets/Lottie/Animation - 1721404976646.json'
import details from '../../assets/Lottie/Animation - 1721405044830.json'







export const about_data = [
    {
        heading: "ABOUT THINKATHON",
        desc:'Welcome to Thinkathon: NextGen Solutions — an event organized by the VentureNest CGC-J. Thinkathon is a unique platform designed for brilliant minds to showcase their innovative excellence. We invite you all to participate in this exciting event, offering a chance to polish your potential and contribute to tackling everyday issues.',
        icon: a1,
    },
    {
        heading: "Event Overview",
        desc:'Are you really seeing what you think you are seeing? Think deeply, observe carefully, and challenge your perceptions!Thinkathon: NextGen Solutions is a pioneering event hosted by VentureNest CGC-J, where participants are encouraged to identify genuine problems and develop comprehensive presentations to propose effective solutions. This event marks the first of its kind at VentureNest CGC-J, promising a stimulating environment for creativity and critical thinking.',
        icon: a2,
    },
    {
        heading: "Invitation to Participate",
        desc:"The E-Cell is proud to organize Thinkathon, where we invite all participants to bring forth real-world problems and their innovative solutions. This is your chance to think, create, and present your ideas in front of esteemed evaluators.Join us at Thinkathon: NextGen Solutions and become a part of this extraordinary journey to solve everyday challenges with your unique insights and ingenuity Let's think, innovate, and make a difference together!",
        icon: a3,
    },
    {
        heading: "Why Participate?",
        desc:"Participating in Thinkathon offers numerous benefits. You'll have the opportunity to:Network with Like-minded Individuals: Meet and collaborate with other passionate individuals who share your drive for innovation and problem-solving.Enhance Your Skills: Develop your critical thinking, problem-solving, and presentation skills in a supportive environment.Gain Recognition: Showcase your ideas to a panel of esteemed evaluators and gain recognition for your innovative solutions.Win Exciting Prizes: Stand a chance to win exciting prizes and opportunities for further development and mentorship.",
        icon: why,
    },
    {
        heading: "Event Details",
        desc:"Thinkathon: NextGen Solutions will take place over a weekend packed with brainstorming sessions, workshops, and presentations. Participants will work in teams to identify problems, develop solutions, and present their findings. The event will culminate in a presentation session where the best ideas will be recognized and awarded.",
        icon: details,
    },
    
    
    
    
];