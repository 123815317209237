import React from 'react';
import {about_data} from './about_data.js'
import './About.css';
import Lottie from 'react-lottie';

const Privacy = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className='about-main'>
      <div className='about-core'>
        {about_data.map((data,index)=>(
          <div className='about-box'>
            {/* <img className='about-box-img' src={data.icon} alt=''/> */}
            <Lottie 
              options={{
                ...defaultOptions,
                animationData: data.icon,
              }}
              height={400}
              width={400}
            />
            <span className='about-text-box'>
              <h1>{data.heading}</h1>
              <br/>
              <p>{data.desc}</p>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Privacy;
